/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import './theme/fonts';
@import "~swiper/scss";

.refresher-refreshing-text,
.refresher-refreshing-icon,
.refresher-pulling-text,
.refresher-pulling-icon {
    color: rgb(0, 159, 204) !important;
    --color: rgb(0, 159, 204) !important;
    font-family: 'Ubuntu' !important;
    font-weight: 400 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.whiteSVG {
    filter: brightness(0) invert(1);;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}

.searchbar-input-container.sc-ion-searchbar-md {
    --box-shadow:  none !important;
}

.centerObj {
    margin-left: auto;
    margin-right: auto;
}

ion-slides ion-card-title {
    font-size: 1.3em;
}

ion-slides ion-card:first-child {
    margin-left: 20px;
}

.dropSlides ion-card {
    margin: 10px;
    min-width: 95%;
    padding: 15px;
}

.dropSlides ion-text {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}

.centerHorizObj {
    margin-top: auto;
    margin-bottom: auto;
}

.mainHeader {
    min-height: 50px;
    max-height: 400px;
}

ion-toolbar {
    --border-width: 0 !important;
}


.ion-page {
    /*padding-top: 45px;*/
    /*max-width: 1300px;
    margin-left: auto;
    margin-right: auto;*/
}

.invisible {
    opacity: 0 !important;
}

.my-custom-class {
    width: 100%;
    max-width: 100%;
    --width: 100%;
    height: 100%;
    max-height: 100%;
    --height: 100%;
}

.alert-wrapper {
    width: 70%;
    max-width: 100%;
    --width: 70%;
}

.verticalMiddle {
    margin-top: auto;
    margin-bottom: auto;
}

.horizontalMiddle {
    margin-left: auto;
    margin-right: auto;
}

.inactiveChip {
    background: white;
}

.activeChip {
    background: rgb(80, 140, 164);
    color: white;
}

ion-badge {
    -webkit-box-shadow: 4px 4px 13px -5px #000000; 
    box-shadow: 4px 4px 13px -5px #000000;
}

.inactiveObj {
    filter: grayscale(100%);
    opacity: .5;
}

.lockBadge {
    font-size: 2em;
    position: absolute;
    top: 90%;
    left: 80%;
}

.addFab {
    bottom: 25px;
    right: 30px;
    --webkit-box-shadow: 4px 4px 13px -5px #000000; 
    box-shadow: 4px 4px 13px -5px #000000;
    border-radius: 50px;
    width: 100px !important;
    height: 100px !important;
}

.bigFab {
    top: 45px;
    right: 50px;
    -webkit-box-shadow: 4px 4px 13px -5px #000000; 
    box-shadow: 4px 4px 13px -5px #000000;
    border-radius: 50px;
    width: 100px !important;
    height: 100px !important;
}

.bigButton {
    width: 100px !important;
    height: 100px !important;
}

ion-button {
    --border-radius: 17px;
}

.psToast {
    color: rgb(250,177,44) !important;
    font-weight: bold !important;
}

.toast {
    font-weight: bold !important;
}

.closeFab {
    top: 45px;
    right: 50px;
    -webkit-box-shadow: 4px 4px 13px -5px #000000; 
    box-shadow: 4px 4px 13px -5px #000000;
    border-radius: 50px;
}

.searchFab {
    bottom: 25px;
    left: 30px;
    -webkit-box-shadow: 4px 4px 13px -5px #000000; 
    box-shadow: 4px 4px 13px -5px #000000;
    border-radius: 50px;
    width: 100px !important;
    height: 100px !important;
}

.categoryButton {
    background-color: white;
    border-radius: 50px;
    text-align: center;
    margin: 60px;
    -webkit-box-shadow: 4px 4px 13px -5px #000000; 
    box-shadow: 4px 4px 13px -5px #000000;
    border: 4px solid grey;
}

.textCenter {
    text-align: center;
}

.bigFont {
    font-size: 1.7em !important;
}

.mediumFont {
    font-size: 1.2em;
}

.smallFont {
    font-size: .9em;
}

.biggerFont {
    font-size: 2.5em !important;
}

.biggestFont {
    font-size: 4.5em !important;
}

.light {
    font-family: 'Ubuntu' !important;
    font-weight: 300 !important;
}

.regular {
    font-family: 'Ubuntu' !important;
    font-weight: 400 !important;
}

.medium {
    font-family: 'Ubuntu' !important;
    font-weight: 500 !important;
}


.headerText {
    margin-top: 60px;
    margin-left: 90px;
    display: inline-block;
}

.subHeaderText {
    margin-left: 90px;
    display: inline-block;
    margin-bottom: 10px;
}

/*#ion-overlay-1 {
    min-height: 100% !important;
    min-width: 100% !important;
    --height: 100% !important;
    --width: 100% !important;
}

#ion-overlay-2 {
    min-height: 100% !important;
    min-width: 100% !important;
    --height: 100% !important;
    --width: 100% !important;
}
*/
ion-modal[id^='ion-overlay-'] {
    /*padding-top: 45px !important;*/
    min-height: 100% !important;
    min-width: 100% !important;
    --height: 100% !important;
    --width: 100% !important;
}

.marginBottomSmall {
    margin-bottom: 5px;
}

.contractListAvatar {
    width: 5vw !important;
    height: 5vw !important;
}