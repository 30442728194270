@font-face {
   font-family: 'Ubuntu';
   src: url('/assets/fonts/Ubuntu-Light.ttf');
   font-weight: 300;
   font-style: normal;
 }

 @font-face {
   font-family: 'Ubuntu';
   src: url('/assets/fonts/Ubuntu-Regular.ttf');
   font-weight: 400;
   font-style: normal;
 }

 @font-face {
   font-family: 'Ubuntu';
   src: url('/assets/fonts/Ubuntu-Medium.ttf');
   font-weight: 500;
   font-style: normal;
 }